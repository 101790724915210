import React, { useState } from 'react';
import '../../style/Signup.css';
import { Link } from 'react-router-dom';

const Signup = ({ setIsSignup }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [organizationName, setOrganizationName] = useState('');
    const [errors, setErrors] = useState({});

    const organisationOptions = [
        { value: '4Seer', label: '4Seer' },
        { value: 'Company1', label: 'Company1' },
    ];

    const handleClick = () => {
        setIsSignup(false);
    }

const validateForm = () => {
    let formErrors = {};
    if (!firstName) formErrors.firstName = 'First Name is required';
    if (!lastName) formErrors.lastName = 'Last Name is required';
    if (!username) formErrors.username = 'Username is required';
    if (!email) formErrors.email = 'Email is required';
    if (!organizationName) formErrors.organizationName = 'Organisation name is required';
    if (!password) formErrors.password = 'Password is required';
    if (password !== confirmPassword) formErrors.confirmPassword = 'Passwords do not match';
    return formErrors;
};

const getPasswordStrength = (password) => {
    if (password.length === 0) return ''; 
    if (password.length < 6) return 'Weak';
    if (password.match(/[A-Z]/) && password.match(/[0-9]/)) return 'Strong';
    return 'Medium';
};

const handleSignup = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
        try {
            const response = await fetch((`${process.env.REACT_APP_API_URL}signup`), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    UserName: username,
                    First_Name: firstName,
                    Last_Name: lastName,
                    Email: email,
                    Password: password,
                    Organization_Name: organizationName,
                }),
            });

            if (response.ok) {
                setErrors({});
                setFirstName('');
                setLastName('');
                setUsername('');
                setEmail('');
                setPassword('');
                setConfirmPassword('');
                setOrganizationName('');
            } else {
                const errorData = await response.json();
                if (errorData.error === "Username already exists. Please choose a different username.") {
                    setErrors({ ...errors, username: "Username already exists." });
                } else {
                    setErrors({ submit: errorData.error });
                }
            }
        } catch (error) {
            setErrors({ submit: 'An error occurred during signup' });
        }
    } else {
        setErrors(formErrors);
    }
};


    return (
                    <div className='signup-card-right'>
                    <h2>Signup</h2>
                    <form onSubmit={handleSignup} className="signup-form">
                        <div className="form-group-signup">
                            <label>First Name</label>
                            <input
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder="First Name"
                            />
                            {errors.firstName && <span className="error">{errors.firstName}</span>}
                        </div>
                        <div className="form-group-signup">
                            <label>Last Name</label>
                            <input
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                placeholder="Last Name"
                            />
                            {errors.lastName && <span className="error">{errors.lastName}</span>}
                        </div>
                        <div className="form-group-signup">
                            <label>Username</label>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                placeholder="Username"
                            />
                            {errors.username && <span className="error">{errors.username}</span>}
                        </div>
                        <div className="form-group-signup">
                            <label>Email</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                            />
                            {errors.email && <span className="error">{errors.email}</span>}
                        </div>
                        <div className="form-group-signup">
                            <label>Organisation</label>
                            <select
                                value={organizationName}
                                onChange={(e) => setOrganizationName(e.target.value)}
                            >
                                {organisationOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            {errors.organizationName && <span className="error">{errors.organizationName}</span>}
                        </div>
                        <div className="form-group-signup">
                            <label>Password</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                            />
                            {errors.password && <span className="error">{errors.password}</span>}
                            <div className={`password-strength ${getPasswordStrength(password)}`}>
                                {getPasswordStrength(password)}
                            </div>
                        </div>
                        <div className="form-group-signup">
                            <label>Confirm Password</label>
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm Password"
                            />
                            {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}
                        </div>
                        <div className="form-group-signup form-group-full">
                            <button type="submit">Signup</button>
                        </div>
                    </form>
                    <div className="signup-footer">
                        <p>Have an account? <Link onClick={handleClick}>Log in here</Link></p>
                    </div>
                </div>
    );
};

export default Signup;

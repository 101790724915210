import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../style/Project.css';
import HelpPage from './HelpPage';
import NewPage from './NewPage'; 
import NavBar from './NavBar';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { FaQuestionCircle } from 'react-icons/fa';
import { CiFilter, CiSearch } from "react-icons/ci";

const RunTable = ({darkMode, purpleMode, redMode, blueMode, yellowMode }) => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isHelpPageOpen, setIsHelpPageOpen] = useState(false);
  const [showHelpDialog, setShowHelpDialog] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const filterOptions = ['SUCCESS', 'FAILED'];
  const [selectedFilter, setSelectedFilter] = useState('')
  const [showDraftProjects, setShowDraftProjects] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [clickOnRow, setClickOnRow] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const navigate = useNavigate();
  const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;
  const { projectname } = useParams(); 

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const fetchaudithistoryexecution = () => {
    fetch(process.env.REACT_APP_API_URL+`audit_history_execution?email=${loggedInUserEmail}&scheduleid=${projectname}`)

      .then((response) => response.json())
      .then((audithistory) => {
        console.log('object level received from backend:', audithistory);
        setData(audithistory);
      })
      .catch((error) => console.error('Error fetching data:', error));
  };
  
  useEffect(() => {
    fetchaudithistoryexecution();
  }, [loggedInUserEmail, projectname]);

  const handleFilterChange = (option) => {
    setSelectedFilter(option);
    setIsFilterActive(true);
  };

  const toggleHelpPage = () => {
    setIsHelpPageOpen(!isHelpPageOpen);
  };

  useEffect(() => {
    // Open the help page automatically when the component mounts
    setIsHelpPageOpen(false);
  }, []);
  
  const showHelpPage = () => {
    setIsHelpPageOpen(true);
  };

  const closeHelpPage = () => {
    setIsHelpPageOpen(false);
  };


    const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = data.filter((item) => {
    const { rundate, processobject, datacountperobject, status, message } = item;
    const searchLowerCase = searchQuery.toLowerCase();
  
    return (
      (!selectedFilter || selectedFilter === 'All' || status === selectedFilter) &&
      (!showDraftProjects || status === 'Draft') &&
      ((rundate && rundate.toLowerCase().includes(searchLowerCase)) ||
        (processobject && processobject.toLowerCase().includes(searchLowerCase)) ||
        (status && status.toLowerCase().includes(searchLowerCase)) ||
        (message && message.toLowerCase().includes(searchLowerCase)) )
    );
  });

  const [sortByOptions, setSortByOptions] = useState({
    date: false,
    status: false,
    update: false,
  });
  
  const handleSortBy = (option) => {
    const sortedData = [...data];
    switch (option) {
      case 'date':
        sortedData.sort((a, b) => {
          if (sortOrder === 'asc') {
            return new Date(a.createddate) - new Date(b.createddate);
          } else {
            return new Date(b.createddate) - new Date(a.createddate);
          }
        });
        break;
      case 'status':
        sortedData.sort((a, b) => {
          if (sortOrder === 'asc') {
            return a.status.localeCompare(b.status);
          } else {
            return b.status.localeCompare(a.status);
          }
        });
        break;
      case 'update':
        sortedData.sort((a, b) => {
          if (sortOrder === 'asc') {
            return new Date(a.LastUpdateDate) - new Date(b.LastUpdateDate);
          } else {
            return new Date(b.LastUpdateDate) - new Date(a.LastUpdateDate);
          }
        });
        break;
      default:
        break;
    }
    setData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <div className='flex'>
      {/* <NavBar />  
        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        /> */}
    <div className="flex-grow">
    {/* <img src={require('../img/thumbnail_4Vue-V5.jpg')} alt="4SEER Logo"  className="logo-image"/> */}
    <div>
      <nav aria-label="breadcrumb">
        <ul className="d-flex breadcrumb">
          <li className="breadcrumb-item">
            <a href="/" className="inactive-link">
             Home
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">        
            <Link to="/RunHistoryMain" className="inactive-link">
            Run History Main
             </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <a href="#" className="active-link">
              Objects level
            </a>
          </li>
        </ul>
      </nav>
      <div className="mt-2 d-flex justify-content-between mb-4">
        <div className='Mainname'>
        <h4 className={`page-heading ${darkMode ? 'dark-mode' : ''}`}>Object level </h4>
        </div>
      </div>
      <ul
        className="nav nav-tabs d-flex align-items-center"
        id="myTab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <a
            className="nav-link active"
            id="overview-tab"
            data-bs-toggle="tab"
            href="#overview"
            role="tab"
            aria-controls="overview"
            aria-selected="true"
          >
            Overview
          </a>
        </li>
        
        <li className="flex-grow-1 d-flex justify-content-end align-items-center">
        <span className={`sort-container ${darkMode ? 'dark-mode' : ''}`}>Sort By:</span>
          <div className="form-check form-check-inline custom-radio">
          <input
            className="form-check-input"
            type="radio"
            name="sortOption"
            id="sortByActiveStatus"
            value="status"
            checked={handleSortBy === 'status'}
            onChange={() => handleSortBy('status')}
            />
            <label className={`form-check-label ${darkMode ? 'dark-mode' : ''}`} htmlFor="sortByCreationDate">
              Active Status
            </label>
          </div>       
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="overview"
          role="tabpanel"
          aria-labelledby="overview-tab"
        >
          <div className={`d-flex justify-content-between mt-4 search-container ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
            <div className="input-container">
              <input
                type="text"
                placeholder="Search by project name."
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <div className="icon">
                <CiSearch />
              </div>
            </div>
            <div>
              <button className="filter-button" onClick={() => setIsFilterActive(!isFilterActive)}>
                <CiFilter className="unfilled-icon" /> Filter
              </button>
              {isFilterActive && (
          <div className="filter-options">
            <button
              className={`filter-option ${selectedFilter === 'All' ? 'active' : ''}`}
              onClick={() => handleFilterChange('All')}
            >
              All
            </button>
            {filterOptions.map((option) => (
              <button
                key={option}
                className={`filter-option ${selectedFilter === option ? 'active' : ''}`}
                onClick={() => handleFilterChange(option)}
              >
                {option === 'SUCCESS'? 'Active': 'In-active'}
              </button>
            ))}
          </div>
        )}
            </div>
          </div>
          <div className="custom-table">
            <div className="custom-table-header">
              <div style={{ flex: 1 }}>RUN DATE</div>
              <div style={{ flex: 1, textAlign: 'center' }}>OBJECTS</div>
              <div style={{ flex: 1, textAlign: 'center' }}>NO OF RECORDS</div>
              <div style={{ flex: 1, textAlign: 'center' }}>STATUS</div>
              <div style={{ flex: 1, textAlign: 'center' }}>MESSAGE</div>
            </div>
          </div>
          <div className={`scrollable-box ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
          <div className="mt-2">
            <div className="custom-table">
              <div className="custom-table-body">
                {filteredData.map((item, index) => (
                  <div
                    key={index}
                    className={`custom-table-row`}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => setClickOnRow(index)}
                  >
                    <div className="custom-table-cell custom-left">
                      {/* <div className="custom-table-container-row custom-left"> */}
                      {item.rundate}
                      {/* </div> */}
                    </div>
                    <div className="custom-table-cell" style={{ display: 'flex', flex: '1', justifyContent: 'center' }}>
                      {/* <div className="custom-table-container-row"> */}
                      {item.processobject}
                      {/* </div> */}
                    </div>
                    <div className="custom-table-cell" style={{ display: 'flex',flex: '1', justifyContent: 'center' }}>
                      {/* <div className="custom-table-container-row"> */}
                      {item.datacountperobject}  
                      {/* </div> */}
                    </div>
                    <div className="custom-table-cell" style={{ display: 'flex', flex: '1', justifyContent: 'center' }}>
                      <span className="active">
                                    <button
                                    className={`status-button ${
                                    item.status === "SUCCESS" ? "active-status" : "In-Active"
                                    }`}
                                    >
                                  {item.status === "SUCCESS" ? "🟢" : "🔴"}
                                  </button>
                                  </span>
                    </div>
                    <div className="custom-table-cell custom-right" style={{ display: 'flex', flex: '1', overflow: 'auto' }}>
                      {item.message}
                    </div>
                  </div>
                ))}
              </div>

            </div>
          </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="drafts"
          role="tabpanel"
          aria-labelledby="drafts-tab"
        >
          Content for the Drafts tab goes here.
        </div>
      </div>
    </div>
    <div className="help-icon" >
      <div className={` ${isHelpPageOpen ? 'help-container' : 'help-container-closed'}`}>      
      <p className={` ${isHelpPageOpen ? 'menu-text-help-bot' : 'question-mark-style '}`} onMouseEnter={showHelpPage} onClick={closeHelpPage} >
        {isHelpPageOpen ? 'May I help you?' : '?'}
       </p>
           {isHelpPageOpen && <HelpPage onClose={toggleHelpPage} />}
            </div>
            <div>     
       </div>
    </div>
  </div>
  </div>
);
}

export default RunTable;
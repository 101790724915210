import React, {useState} from "react";
import { FiMenu, FiArrowLeft } from 'react-icons/fi';
import '../style/CollapseControl.css';

const CollapseControl = ({ onClick,collapsed, setIsDialogOpen, darkMode, purpleMode, redMode, blueMode, yellowMode }) => {

    return (
        <div className="collapse-control-layout">
            {collapsed ? (
                <div className="hamburg-menu" onClick={onClick}>
                    <FiMenu />
                </div>
            ) : (
                <div className="back-button-menu" onClick={onClick}>
                    <FiArrowLeft />
                </div>
            )}
            <div className="addProject-menu-button">
                <button onClick={() => setIsDialogOpen(true)} className="addP-button">
                            + ADD
                </button>
            </div>
            <div className={`menu-text-sidebar ${darkMode ? 'dark-mode' : ''}`}>Menu</div>
        </div>
    );
};

export default CollapseControl;

import React, { useState, useEffect, useRef } from "react";
import toast, { Toaster} from 'react-hot-toast';
import Select from 'react-select';
import HelpPage from './HelpPage';
import NewPage from './NewPage'; 
import '../style/Scheduler.css'
import ClockTimeInput from './ClockTimeInput';
import Calendar from './Calendar';
import { useNavigate, useLocation  } from 'react-router-dom';
import AnalogClock from "./AnalogClock";
import DigitalClock from "./DigitalClock";
import OneTimeOccurenceClock from "./OneTimeOccurenceClocks";
import dayjs from 'dayjs';


const Scheduler = ({ darkMode, purpleMode, redMode, blueMode, yellowMode }) => {

    const [successMessage, setSuccessMessage] = useState("");
    const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;
    const [projectData, setProjectData] = useState([]);
    const [isHelpPageOpen, setIsHelpPageOpen] = useState(false);
    const [enableForm, setEnableForm] = useState(false);
    const [descriptionPreview, setDescriptionPreview] = useState("");
    const [enableIncrementalSection, setEnableIncrementalSection] = useState(false);
    // const [hours, setHours] = useState(currentTimeFormatted.format('HH'));
    // const [minutes, setMinutes] = useState(currentTimeFormatted.format('mm'));

    const [jobDetails, setJobDetails] = useState({});

    const currentTimeFormatted = dayjs().format('hh:mm:A');

    const [selectedTime, setSelectedTime] = useState(currentTimeFormatted);

    const [occursOnceAtRecHours, setOccursOnceAtRecHours] = useState(dayjs(selectedTime).format('hh'));
    const [occursOnceAtRecMinutes, setOccursOnceAtRecMinutes] = useState(dayjs(selectedTime).format('mm'));
    const [occursOnceAtRecPeriod, setOccursOnceAtRecPeriod] = useState(dayjs(selectedTime).format('AM'));
    const [startingAtRecHours, setStartingAtRecHours] = useState(dayjs(selectedTime).format('hh'));
    const [startingAtRecMinutes, setStartingAtRecMinutes] = useState(dayjs(selectedTime).format('mm'));
    const [startingAtRecPeriod, setStartingAtRecPeriod] = useState(dayjs(selectedTime).format('AM'));

    const [endingAtRecHours, setEndingAtRecHours] = useState(dayjs(selectedTime).format('hh'));
    const [endingAtRecMinutes, setEndingAtRecMinutes] = useState(dayjs(selectedTime).format('mm'));
    const [endingAtRecPeriod, setEndingAtRecPeriod] = useState(dayjs(selectedTime).format('AM'));

    const [formattedDateOneTime, setFormattedDateOneTime] = useState('');
    const [formattedStartDate, setFormattedStartDate] = useState('');
    const [formattedEndDate, setFormattedEndDate] = useState('');
    
    const incrementalSectionRef = useRef(null);
    const [formData, setFormData] = useState({
        jobName: "",
        projectId: "",
        projectName: '',
        loadType: "",
        scheduleType: '',
        weekDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        date_OneTime: '',
        time_OneTime: '',
        dailyFrequency: 'occursOnceAt',
        frequencyOfOccurrence_Rec: '',
        monthlyFreq: 'monthlySingle',
        monthlyDay: '1',
        monthCount: '',
        specificDays:'',
        recursEveryDay_Rec: '1',
        recursEveryWeek_Rec: '1',
        recursEveryMonth_Rec: '1',
        recursEveryDaily: '',
        recursEveryUnitDaily:'',
        occursEvery_Rec: 'Daily',
        occursOnceAt_Rec: '',
        startingAt_Rec: '',
        endsAt_Rec: '',
        startDate: '',
        endDate: '',
        noEndDate: '',
        description: '',
        SchedulerId: '',
    });

    const toggleHelpPage = () => {
        setIsHelpPageOpen(!isHelpPageOpen);
      };
    
      useEffect(() => {
        // Open the help page automatically when the component mounts
        setIsHelpPageOpen(true);
      }, []);
      
      const showHelpPage = () => {
        setIsHelpPageOpen(true);
      };
    
      const closeHelpPage = () => {
        setIsHelpPageOpen(false);
      };

    const navigate = useNavigate();

    const scheduleTypeOptions = [
        { value: 'Once', label: 'One Time' },
        { value: 'Recurring', label: 'Recurring' },
    ];

    const loadOptions = [
        { value: 'direct', label: 'Direct' },
        { value: 'incremental', label: 'Incremental' },
    ];

    const occursOptions = [
        { value: 'Daily', label: 'Daily' },
        { value: 'Weekly', label: 'Weekly' },
        { value: 'Monthly', label: 'Monthly' },
    ];

    const recursEveryOptions = [
        { value: 'Day', label: 'Day' },
        { value: 'Week', label: 'Week' },
        { value: 'Month', label: 'Month' },
    ];

    const specificDayOptions = [
        { value: 'first', label: 'First' },
        { value: 'second', label: 'Second' },
        { value: 'third', label: 'Third' },
        { value: 'fourth', label: 'Fourth' },
    ];

    const weekDayOptions = [
        { value: 'monday', label: 'Monday'},
        { value: 'tuesday', label: 'Tuesday'},
        { value: 'wednesday', label: 'Wednesday'},
        { value: 'thursday', label: 'Thursday'},
        { value: 'friday', label: 'Friday'},
        { value: 'saturday', label: 'Saturday'},
        { value: 'sunday', label: 'Sunday'},
    ]

    const getHoverBackgroundColor = () => {
        if (darkMode) {
            return '#1c1e21'; 
        } else if (purpleMode) {
            return '#B295F2';
        } else if (redMode) {
            return '#bd4a5e'; 
        } else if (blueMode) {
            return '#63c3d6'; 
        } else if (yellowMode) {
            return '#c9b653'; 
        } else {
            return '#3F787E'; // Default hover color if no mode is active
        }
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#F7F8F9' : '#fff', 
            color: state.isSelected ? '#6A6A6A' : '#6A6A6A', 
            ':hover': {
                backgroundColor: getHoverBackgroundColor(), 
                color: '#fff',
            },
        }),
    };

    const updateFormData = (name, value) => {
        console.log(name+' '+value);
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleInputChange = (name, value) => {
        if (name === 'noEndDate' && value) {
            setFormData({
                ...formData,
                endDate: '',
                [name]: value,
            });
        } else if(name==='loadType' && value==='incremental'){
            setEnableIncrementalSection(true);
            updateFormData(name, value);
        } else if(name==='loadType' && value==='direct'){
            setEnableIncrementalSection(false);
            updateFormData(name, value);
        } else if ((name === 'dailyFrequency' && value === 'occursOnceAt')) {
            setFormData({
                ...formData,
                dailyFrequency: 'occursOnceAt',
                recursEveryDaily: '', 
                recursEveryUnitDaily: '',
                endsAt_Rec: '',
                startingAt_Rec: ' ',
              });
        } else if (name === 'dailyFrequency' && value === 'occursEvery') {
            setFormData({
                ...formData,
                dailyFrequency: 'occursEvery',
                occursOnceAt_Rec: '', 
              });
        }else {
            updateFormData(name, value);
        }

        updateDescriptionPreview();
    };

    useEffect(() => {
        updateDescriptionPreview();
    }, [formData]);

    const updateDescriptionPreview = () => {
        //Once
        const selectedType = formData.scheduleType === 'Once' ? 'Occurs on' : 'Occurs every';
        const datePart = formData.scheduleType === 'Once' ? formData.date_OneTime : ''; 
        const timePart = formData.scheduleType === 'Once' ? formData.time_OneTime : '';

        //Recurring    
        const frequencyOfOccurence = formData.occursEvery_Rec === 'Daily' ? `day(s)` : formData.occursEvery_Rec=== 'Weekly' ? `week(s)` : `month(s)`;
        const frequency = formData.occursEvery_Rec === 'Daily' ? formData.recursEveryDay_Rec : formData.occursEvery_Rec=== 'Weekly' ? formData.recursEveryWeek_Rec : ``;
        const endDateCheck = formData.noEndDate === true && formData.scheduleType === 'Recurring' ? `Schedule will be used starting on ${formData.startDate}` : `Schedule will be used between ${formData.startDate} and ${formData.endDate}`
        const dailyFrequencyCheck = formData.dailyFrequency==='occursOnceAt' ? `at ${formData.occursOnceAt_Rec}` : `every ${formData.recursEveryDaily} ${formData.recursEveryUnitDaily} between ${formData.startingAt_Rec} and ${formData.endsAt_Rec}`
        const weekDaySelection = formData.weekDays;
        const suffixSelection = formData.recursEveryMonth_Rec === 3 ? `rd` : formData.recursEveryMonth_Rec === 2 ? `nd` : formData.recursEveryMonth_Rec  === 1 ? `` : `th`;
        const selection = formData.occursEvery_Rec === 'Monthly' ? `${formData.recursEveryMonth_Rec}${suffixSelection} month on day ${formData.monthlyDay} ${dailyFrequencyCheck} ` : formData.occursEvery_Rec === 'Weekly' ? `${frequency} ${frequencyOfOccurence} on ${weekDaySelection} ${dailyFrequencyCheck}`: `${frequency} ${frequencyOfOccurence} ${dailyFrequencyCheck}`;

        const description = formData.scheduleType === 
            'Once'? 
            `${selectedType} ${datePart} at ${timePart}.`: 
            `${selectedType} ${selection}. ${endDateCheck}.` ;

        // Sets the description preview
        setDescriptionPreview(description);
    };

    const handleWeekdayChange = (day) => {
        const updatedWeekdays = [...formData.weekDays];

        if (updatedWeekdays.includes(day)) {
            const index = updatedWeekdays.indexOf(day);
            updatedWeekdays.splice(index, 1);
        } else {
            updatedWeekdays.push(day);
        }

        updateFormData('weekDays', updatedWeekdays);
    };

    const fetchProjects = () => {
        fetch(`${process.env.REACT_APP_API_URL}projects?email=${loggedInUserEmail}`)
          .then((response) => response.json())
          .then((projects) => {
            console.log('Projects received from backend for scheduler dropdown:', projects);
            setProjectData(projects);
          })
          .catch((error) => console.error('Error fetching data in scheduler dropdown:', error));
      };
      
    const fetchProjectsOnce = () => {
        fetchProjects();
    };

    useEffect(() => {
        fetchProjectsOnce();
    }, []);

    const projectNameOptions = projectData.map((project) => ({
        value: project.projectid,
        label: project.projectname,
    }));

    const schedulersave = () => {
        const isJobNameValid = () => {
            if (!Array.isArray(jobNameValidationData)) {
                console.error('jobNameValidationData is not an array:', jobNameValidationData);
                return false;
              }
              return jobNameValidationData.includes(formData.jobName);
          };
        if(!editMode){
        if (isJobNameValid()) {
            toast.error('Job name already exists. Please choose a different name.');
            return;
        }
    }
        console.log(formData);
        const detailsToSend = {
            useremail:loggedInUserEmail,
            jobName: formData.jobName,
            projectId: formData.projectId,
            scheduleType: formData.scheduleType,
            loadType: formData.loadType,
            date_OneTime: formData.date_OneTime,
            time_OneTime: formData.time_OneTime,
            frequencyOfOccurrence_Rec: formData.frequencyOfOccurrence_Rec,
            dailyFrequency: formData.dailyFrequency,
            monthlyFreq: formData.monthlyFreq,
            monthlyDay: formData.monthlyDay,
            monthCount: formData.monthCount,
            specificDays: formData.specificDays,
            weekDays: formData.weekDays,
            recursEveryDay_Rec: formData.recursEveryDay_Rec,
            recursEveryWeek_Rec: formData.recursEveryWeek_Rec,
            recursEveryMonth_Rec: formData.recursEveryMonth_Rec,
            recursEveryDaily: formData.recursEveryDaily,
            recursEveryUnitDaily:formData.recursEveryUnitDaily,
            occursEvery_Rec: formData.occursEvery_Rec,
            occursOnceAt_Rec: formData.occursOnceAt_Rec,
            startingAt_Rec: formData.startingAt_Rec,
            endsAt_Rec: formData.endsAt_Rec,
            startDate: formData.startDate,
            endDate: formData.endDate,
            noEndDate: formData.noEndDate,
            description: descriptionPreview,
        };
        const editedDetailsToSend = {
            SchedulerId:formData.SchedulerId,
            useremail:loggedInUserEmail,
            jobName: formData.jobName,
            projectId: formData.projectId,
            scheduleType: formData.scheduleType,
            loadType: formData.loadType,
            date_OneTime: formData.date_OneTime,
            time_OneTime: formData.time_OneTime,
            frequencyOfOccurrence_Rec: formData.frequencyOfOccurrence_Rec,
            monthlyFreq: formData.monthlyFreq,
            monthlyDay: formData.monthlyDay,
            monthCount: formData.monthCount,
            specificDays: formData.specificDays,
            weekDays: formData.weekDays,
            recursEveryDay_Rec: formData.recursEveryDay_Rec,
            recursEveryWeek_Rec: formData.recursEveryWeek_Rec,
            recursEveryMonth_Rec: formData.recursEveryMonth_Rec,
            recursEveryDaily: formData.recursEveryDaily,
            recursEveryUnitDaily:formData.recursEveryUnitDaily,
            occursEvery_Rec: formData.occursEvery_Rec,
            occursOnceAt_Rec: formData.occursOnceAt_Rec,
            startingAt_Rec: formData.startingAt_Rec,
            endsAt_Rec: formData.endsAt_Rec,
            startDate: formData.startDate,
            endDate: formData.endDate,
            noEndDate: formData.noEndDate,
            description: descriptionPreview,
        };

        console.log('Details to send For Final Save', detailsToSend);
      
        const requiredFields = ['jobName', 'projectId', 'scheduleType'];
        
        const emptyFields = requiredFields.filter(field => !formData[field]);
        if (emptyFields.length > 0) {
          toast.error(`Please complete the following fields: ${emptyFields.join(', ')}`);
          return;
        } 
        if(formData.scheduleType === "Once"){
            const requiredFieldsOnce = ['date_OneTime', 'time_OneTime'];
            const emptyFields = requiredFieldsOnce.filter(field => !formData[field]);
            if (emptyFields.length > 0) {
                toast.error(`Please fill the missing fields in One Time Occurence`);
                return;
            }
        } 
        if(formData.scheduleType === "Recurring"){
            if(formData.occursEvery_Rec === "Daily"){
                const requiredFieldsRecurringFrequency = ['recursEveryDay_Rec'];
                const emptyFields = requiredFieldsRecurringFrequency.filter(field => !formData[field]);
                if (emptyFields.length > 0) {
                    toast.error(`Please fill the missing fields in Frequency`);
                    return;
                  }
            } 
            if(formData.occursEvery_Rec === "Weekly"){
                const requiredFieldsRecurringFrequency = ['recursEveryWeek_Rec', 'weekDays'];
                const emptyFields = requiredFieldsRecurringFrequency.filter(field => !formData[field]);
                if (emptyFields.length > 0) {
                    toast.error(`Please fill the missing fields in Frequency`);
                    return;
                  }
            }
            if(formData.occursEvery_Rec === "Monthly"){
                const requiredFieldsRecurringFrequency = ['monthlyDay', 'recursEveryMonth_Rec'];
                const emptyFields = requiredFieldsRecurringFrequency.filter(field => !formData[field]);
                if (emptyFields.length > 0) {
                    toast.error(`Please fill the missing fields in Frequency`);
                    return;
                  }
            }
            if(formData.dailyFrequency === "occursOnceAt"){
                const requiredFieldsRecurringFrequency = ['occursOnceAt_Rec'];
                const emptyFields = requiredFieldsRecurringFrequency.filter(field => !formData[field]);
                if (emptyFields.length > 0) {
                    toast.error(`Please fill the missing fields in Daily Frequency`);
                    return;
                  }
            }
            if(formData.dailyFrequency === "occursEvery"){
                const requiredFieldsRecurringFrequency = ['recursEveryDaily', 'recursEveryUnitDaily', 'startingAt_Rec', 'endsAt_Rec'];
                const emptyFields = requiredFieldsRecurringFrequency.filter(field => !formData[field]);
                if (emptyFields.length > 0) {
                    toast.error(`Please fill the missing fields in Daily Frequency`);
                    return;
                  }
            }
            if(formData.dailyFrequency === "occursEvery"){
                const requiredFieldsRecurringFrequency = ['recursEveryDaily', 'recursEveryUnitDaily', 'startingAt_Rec', 'endsAt_Rec'];
                const emptyFields = requiredFieldsRecurringFrequency.filter(field => !formData[field]);
                if (emptyFields.length > 0) {
                    toast.error(`Please fill the missing fields in Daily Frequency`);
                    return;
                  }
            }
            const requiredFieldsRecurring = ['startDate', 'endDate', 'noEndDate'];
            const emptyFields = requiredFieldsRecurring.filter(field => !formData[field]);
            console.log('empty fields duration'+emptyFields+emptyFields.length)
            if (emptyFields.length > 1) {
                toast.error(`Please complete the missing fields in Duration`);
                return;
              }
        }
      
        console.log('Details to send For Final Save2', detailsToSend);
        fetch(process.env.REACT_APP_API_URL + 'scheduler', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(editMode? editedDetailsToSend: detailsToSend),
        })
        .then((response) => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
        })
        .then((responseData) => {
        if (responseData.status === 'success') {
            console.log('Details saved successfully!');
            setSuccessMessage("Details saved successfully!");


            toast.success('Details saved successfully!');
            
            setTimeout(() => {
            setSuccessMessage('');
            navigate('/Scheduler');
            }, 1000);
        } else {
            console.error('Error saving details:', responseData.message);
            toast.error('Error saving details. Please try again.');
            setSuccessMessage("Error saving details. Please try again.");
        }
        })
        .catch((error) => {
        console.error('Fetch error:', error);
        });
    };

    const renderRecursEveryInput = () => {
        if (formData.occursEvery_Rec === 'Daily' || formData.occursEvery_Rec === 'Weekly' || formData.occursEvery_Rec === 'Monthly') {
          const labelSuffix = formData.occursEvery_Rec === 'Daily' ? 'day' : formData.occursEvery_Rec === 'Weekly' ? 'week(s) on' : 'month';
          const formDataType = formData.occursEvery_Rec === 'Daily' ? 'Day' : formData.occursEvery_Rec === 'Weekly' ? 'Week' : 'Month';
          return (
            <div className="recurs-every-section">
              <label className={`scheduler-label ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
                Recurs every:
                {formData.occursEvery_Rec === 'Weekly' && (
                  <div className="recurs-every-week-section-1">
                    <input
                      type="number"
                      name={`recursEvery${formDataType}_Rec`}
                      value={formData[`recursEvery${formDataType}_Rec`]}
                      disabled={formData.scheduleType === 'Once' || enableForm===false}
                      onChange={(e) => {
                        // Handles backspace key to allow clearing the input
                        const inputValue = e.target.value === '' ? '' : Math.max(1, Math.min(100, parseInt(e.target.value, 10)));
                        handleInputChange(`recursEvery${formDataType}_Rec`, inputValue);
                    }}
                    />
                    <div className="recurs-every-week-section-2">{labelSuffix}</div>
                    <div className='recurs-every-week-section-3'>
                      {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) => (
                        <label key={day}>
                          <input
                            type="checkbox"
                            name={`weekDay_${day}`}
                            checked={formData.weekDays.includes(day)}
                            disabled={formData.scheduleType === 'Once' || enableForm===false}
                            onChange={() => {handleWeekdayChange(day);
                                // setFormData(prevData => ({
                                //     ...prevData,
                                // }));
                            }}
                          />
                          {day}
                        </label>
                      ))}
                    </div>
                  </div>
                )}
                {formData.occursEvery_Rec === 'Monthly' && (
                <div>
                    <div>
                    <label>
                        <input
                        type="radio"
                        name="monthlyOptions"
                        value="monthlySingle"
                        checked={formData.monthlyFreq === 'monthlySingle'}
                        onChange={() => handleInputChange('monthlyFreq', 'monthlySingle')}
                        disabled={formData.scheduleType === 'Once' || enableForm===false}
                        />
                        Day
                        <input
                        type="number"
                        name="monthlyDay"
                        value={formData.monthlyDay}
                        onChange={(e) => {
                            // Handles backspace key to allow clearing the input
                            const inputValue = e.target.value === '' ? '' : Math.max(1, Math.min(31, parseInt(e.target.value, 10)));
                            handleInputChange('monthlyDay', inputValue);
                          }}
                        disabled={formData.scheduleType === 'Once'}
                        />
                        of every
                        <input
                        type="number"
                        name="monthCount"
                        value={formData.recursEveryMonth_Rec}
                        onChange={(e) => {
                            // Handles backspace key to allow clearing the input
                            const inputValue = e.target.value === '' ? '' : Math.max(1, Math.min(12, parseInt(e.target.value, 10)));
                            handleInputChange(`recursEvery${formDataType}_Rec`, inputValue);
                            setFormData(prevData => ({
                                ...prevData,
                            }));
                        }}
                        disabled={formData.scheduleType === 'Once'}
                        />
                        month(s)
                    </label>
                    </div>
                </div>
                )}

                {formData.occursEvery_Rec === 'Daily' && (
                  <div>
                    <input
                      type="number"
                      name={`recursEvery${formDataType}_Rec`}
                      value={formData[`recursEvery${formDataType}_Rec`]}
                      disabled={formData.scheduleType === 'Once' || enableForm===false}
                      onChange={(e) => {
                        const inputValue = e.target.value === '' ? '' :  Math.max(1, e.target.value);
                        handleInputChange(`recursEvery${formDataType}_Rec`, inputValue);
                      }}
                    />
                    <div>{labelSuffix}(s)</div>
                  </div>
                )}
              </label>
            </div>
          );
        }
        return null;
    };  

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form Data:', formData);
    };

    const handleCancel = () => {
        navigate('/Scheduler');
    };


    const location = useLocation();
    const jobNameValidationData = location.state?.jobNameValidationData;
    const editMode = location.state?.editMode;
    console.log('EditMode in scheduler: ', editMode);
    const clickedItem = location.state?.clickedItem; //Check if exit for add condition later.  DOn't miss it!
    console.log('clicked items in scheduler:', clickedItem);

    useEffect(() => {
        if (editMode && clickedItem) {
            fetch(`${process.env.REACT_APP_API_URL}scheduler_id_details?scheduler_id=${clickedItem}`)
                .then((response) => response.json())
                .then((job) => {
                    setJobDetails(job.scheduler_details);
                    console.log('Job data received in scheduler: ', job);
                    setEnableForm(true); // Set enable form once after fetching job details
                })
                .catch((error) => console.error('Error fetching data:', error));
        }
    }, [clickedItem]);
    
    // Update formData and descriptionPreview when jobDetails changes
    useEffect(() => {
        if(jobDetails){
            setFormData((prevData) => ({
                ...prevData,
                jobName: jobDetails.jobName,
                projectName: jobDetails.projectName,
                projectId: jobDetails.projectId,
                scheduleType: jobDetails.scheduleType,
                date_OneTime: jobDetails.date_OneTime,
                time_OneTime: jobDetails.time_OneTime,
                occursEvery_Rec: jobDetails.occursEvery_Rec,
                recursEveryDay_Rec: jobDetails.recursEveryDay_Rec,
                recursEveryMonth_Rec: jobDetails.recursEveryMonth_Rec,
                startDate: jobDetails.startDate,
                endDate: jobDetails.endDate,
                SchedulerId: clickedItem,
                weekDays: jobDetails.weekDays,
                dailyFrequency: jobDetails.dailyFrequency,
                frequencyOfOccurrence_Rec: '',
                monthlyFreq: jobDetails.monthlyFreq,
                monthlyDay: jobDetails.monthlyDay,
                monthCount: jobDetails.monthlyCount,
                specificDays: jobDetails.specificDays,
                recursEveryWeek_Rec: jobDetails.recursEveryWeek_Rec,
                recursEveryDaily: jobDetails.recursEveryDaily,
                recursEveryUnitDaily:jobDetails.recursEveryUnitDaily,
                occursOnceAt_Rec: jobDetails.occursOnceAt_Rec,
                startingAt_Rec: jobDetails.startingAt_Rec,
                endsAt_Rec: jobDetails.endsAt_Rec,
                noEndDate: jobDetails.noEndDate, 
                description: jobDetails.description,
            }));
            setDescriptionPreview(jobDetails.description);
            console.log('formData after editMode', formData);
            console.log('Job data received in scheduler2: ', jobDetails);
            console.log('Description after editMode', descriptionPreview);
        }
    }, [jobDetails]);

    useEffect(() => {
        if (jobDetails && jobDetails.occursOnceAt_Rec) {
            console.log('Time passed on for occursOnceAt_Rec is ', jobDetails.occursOnceAt_Rec);
            const parsedTime = dayjs(jobDetails.occursOnceAt_Rec, 'HH:mm').format('hh:mm:A');
            const parsedHours = dayjs(jobDetails.occursOnceAt_Rec, 'HH:mm').format('hh');
            const parsedMinutes = dayjs(jobDetails.occursOnceAt_Rec, 'HH:mm').format('mm');
            const parsedPeriod = dayjs(jobDetails.occursOnceAt_Rec, 'HH:mm').format('A');
            setSelectedTime(parsedTime);
            setOccursOnceAtRecHours(parsedHours);
            setOccursOnceAtRecMinutes(parsedMinutes);
            setOccursOnceAtRecPeriod(parsedPeriod);
        }
        console.log('selectedTime after if statement for occurseOnceAtRec is: ', selectedTime);

        if (jobDetails && jobDetails.startingAt_Rec) {
            console.log('Time passed on for occursOnceAt_Rec is ', jobDetails.startingAt_Rec);
            const parsedTime = dayjs(jobDetails.startingAt_Rec, 'HH:mm').format('hh:mm:A');
            const parsedHours = dayjs(jobDetails.startingAt_Rec, 'HH:mm').format('hh');
            const parsedMinutes = dayjs(jobDetails.startingAt_Rec, 'HH:mm').format('mm');
            const parsedPeriod = dayjs(jobDetails.startingAt_Rec, 'HH:mm').format('A');
            setSelectedTime(parsedTime);
            setStartingAtRecHours(parsedHours);
            setStartingAtRecMinutes(parsedMinutes);
            setStartingAtRecPeriod(parsedPeriod);
        }
        console.log('selectedTime after if statement for startingAt_Rec is: ', selectedTime);

        if (jobDetails && jobDetails.endsAt_Rec) {
            console.log('Time passed on for occursOnceAt_Rec is ', jobDetails.endsAt_Rec);
            const parsedTime = dayjs(jobDetails.endsAt_Rec, 'HH:mm').format('hh:mm:A');
            const parsedHours = dayjs(jobDetails.endsAt_Rec, 'HH:mm').format('hh');
            const parsedMinutes = dayjs(jobDetails.endsAt_Rec, 'HH:mm').format('mm');
            const parsedPeriod = dayjs(jobDetails.endsAt_Rec, 'HH:mm').format('A');
            setSelectedTime(parsedTime);
            setEndingAtRecHours(parsedHours);
            setEndingAtRecMinutes(parsedMinutes);
            setEndingAtRecPeriod(parsedPeriod);
        }
        console.log('selectedTime after if statement for endsAt_Rec is: ', selectedTime);

        if(jobDetails && jobDetails.date_OneTime){
            console.log('Date_OneTime value is: ', jobDetails.date_OneTime);
            setFormattedDateOneTime(jobDetails.date_OneTime);
            if(jobDetails.startDate){
                setFormattedStartDate(null);
            }
            if(jobDetails.endDate){
                setFormattedEndDate(null);
            }
         }

        if (jobDetails && jobDetails.startDate) {
            const startDate = new Date(jobDetails.startDate);
            setFormattedStartDate(`${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`);
            console.log('Formatted StartDate value is: ', formattedStartDate);
            setFormData((prevData) => ({
                ...prevData,
                startDate: formattedStartDate,
            }));
          }

        if (jobDetails && jobDetails.endDate) {
            const endDate = new Date(jobDetails.endDate);
            setFormattedEndDate(`${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}-${endDate.getDate().toString().padStart(2, '0')}`);
            console.log('Formatted EndDate value is: ', formattedEndDate);
            setFormData((prevData) => ({
                ...prevData,
                endDate: formattedEndDate,
            }));
        }
    }, [jobDetails]);
    

    //   useEffect(() => {
    //     if (editMode) {
          
    //     }
    //   }, [editMode]); 
      
    
    if (!jobNameValidationData) {       
        return console.log('Error: jobNameValidationData is missing');
    }



    return (
        <div className={`scheduler-container ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
        <form onSubmit={handleSubmit}>
            <div className="scheduler-field">
                <label for="jobName" className= {`scheduler-label ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>Job Name</label>
                <input
                    type="text"
                    id="jobName"
                    name="jobName"
                    defaultValue={jobDetails.jobName}
                    onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                    placeholder="Enter the job name"
                    className="input-field"
                    // disabled={editMode}
                />
            </div>
            <div className="scheduler-field">
                <label for="projectName" className={`scheduler-label ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>Project Name</label>
                    { editMode ? (
                    <input
                        type="text"
                        id="projectId"
                        name="projectName"
                        defaultValue={jobDetails.projectName}
                        // onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        placeholder="Enter the project name"
                        className="input-field"
                        disabled={editMode}
                    /> ) : (
                    <Select
                        id="projectId"
                        name="projectName"
                        label="Select project type"
                        options={projectNameOptions}
                        value={
                            editMode
                            ? { label: jobDetails.projectName, value: jobDetails.projectName }
                            : projectNameOptions.find((option) => option.value === jobDetails.projectName)
                        }
                        onChange={(selectedOption) => 
                            setFormData({
                            ...formData,
                            projectId: selectedOption.value,
                            projectName: selectedOption.label,
                            })
                        }
                        isSearchable={true}
                        styles={customStyles}
                        className="select-wrapper-scheduler"
                        disabled={editMode}
                        // classNamePrefix="react-select"
                    >
                    </Select>
                    )}
            </div>
            <div className="scheduler-field">
                <label for="scheduleType" className={`scheduler-label ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>Scheduler Type</label>
                <div className="select-container">
                    <Select
                    id="scheduleType"
                    name="scheduleType"
                    label="Select schedule type"
                    options={scheduleTypeOptions}
                    value={
                        editMode
                        ? { label: formData.scheduleType, value: formData.scheduleType }
                        : scheduleTypeOptions.find((option) => option.value === formData.scheduleType)
                    }
                    onChange={(selectedOption) => {
                        handleInputChange('scheduleType', selectedOption.value)
                        setEnableForm(true);
                        if(selectedOption.value==="Once"){
                            setFormData(prevData => ({
                                ...prevData,
                                recursEveryDay_Rec: '1',
                                weekDays: [],
                                date_OneTime: '',
                                time_OneTime: '',
                                dailyFrequency: '',
                                frequencyOfOccurrence_Rec: '',
                                monthlyFreq: '',
                                monthlyDay: '',
                                monthCount: '',
                                specificDays:'',
                                recursEveryWeek_Rec: '',
                                recursEveryMonth_Rec: '',
                                recursEveryDaily: '',
                                recursEveryUnitDaily:'',
                                occursEvery_Rec: '',
                                occursOnceAt_Rec: '',
                                startingAt_Rec: '',
                                endsAt_Rec: '',
                                startDate: '',
                                endDate: '',
                                noEndDate: '',
                                description: '',
                            }))
                        } else {
                        setFormData(prevData => ({
                            ...prevData,
                            weekDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                            date_OneTime: '',
                            time_OneTime: '',
                            dailyFrequency: 'occursOnceAt',
                            frequencyOfOccurrence_Rec: '',
                            monthlyFreq: 'monthlySingle',
                            monthlyDay: '1',
                            monthCount: '',
                            specificDays:'',
                            recursEveryDay_Rec: '',
                            recursEveryWeek_Rec: '1',
                            recursEveryMonth_Rec: '1',
                            recursEveryDaily: '',
                            recursEveryUnitDaily:'',
                            occursEvery_Rec: '',
                            occursOnceAt_Rec: '',
                            startingAt_Rec: '',
                            endsAt_Rec: '',
                            startDate: '',
                            endDate: '',
                            noEndDate: '',
                            description: '',
                        }));
                    }}}
                    className="select-wrapper-scheduler"
                    // classNamePrefix="react-select"
                    styles={customStyles}
                    >
                    </Select>
                </div>
            </div>

            {/* Section Divider */}
            <div className="styled-container">
                <div className={`styled-divider-left ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}></div>
                <span className={`styled-text ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>One Time Occurrence</span>
                <div className={`styled-divider-right ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}></div>
            </div>

            <div className="one-time-occurence-section">
                <label>
                    <Calendar 
                        handleInputChange={handleInputChange} 
                        calendarType = {'date_OneTime'} 
                        disabled={formData.scheduleType === 'Recurring' || enableForm===false} 
                        darkMode = {darkMode} 
                        purpleMode={purpleMode}
                        redMode={redMode} 
                        blueMode={blueMode} 
                        yellowMode={yellowMode} 
                        editMode={editMode}
                        formattedDate={formattedDateOneTime}
                        />
                </label>
                <br />
                <OneTimeOccurenceClock 
                    handleInputChange={handleInputChange} 
                    disabled={formData.scheduleType === 'Recurring' || enableForm===false}
                    darkMode={darkMode}
                    purpleMode={purpleMode} 
                    redMode={redMode} 
                    blueMode={blueMode} 
                    yellowMode={yellowMode}
                    clockType = {'time_OneTime'}
                    jobDetails = {jobDetails}
                />
            </div>

            {/* Section Divider */}
            <div className="styled-container">
                <div className="styled-divider-left"></div>
                <span className="styled-text">Frequency</span>
                <div className="styled-divider-right"></div>
            </div>

            <div className="frequency-container">
                <div className="column">
                    <div className="scheduler-field">
                        <label for="scheduleType" className={`scheduler-label ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>Occurs</label>
                        <Select
                        id="occursEvery_Rec"
                        name="occursEvery_Rec"
                        label="Select frequency type"
                        options={occursOptions}
                        value={
                            editMode
                              ? { label: formData.occursEvery_Rec, value: formData.occursEvery_Rec }
                              : occursOptions.find((option) => option.value === formData.occursEvery_Rec)
                          }
                        onChange={(selectedOption) => {
                            handleInputChange('occursEvery_Rec', selectedOption.value);
                            if(selectedOption.value === 'Daily' && formData.scheduleType === 'Recurring'){
                                setFormData(prevData => ({
                                    ...prevData,
                                    recursEveryWeek_Rec: '', 
                                    weekDays: '',
                                    monthlyDay: '',
                                    monthlyFreq:'',
                                    recursEveryMonth_Rec: '',
                                    recursEveryDay_Rec: '1',
                                }));
                            }else if(selectedOption.value === 'Weekly' && formData.scheduleType === 'Recurring'){
                                setFormData(prevData => ({
                                    ...prevData,
                                    recursEveryDay_Rec: '',
                                    monthlyDay: '',
                                    monthlyFreq: '',
                                    recursEveryMonth_Rec: '',
                                    weekDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                                    recursEveryWeek_Rec: '1',
                                }));
                            } else if (selectedOption.value === 'Monthly' && formData.scheduleType === 'Recurring'){
                                setFormData(prevData => ({
                                    ...prevData,
                                    recursEveryDay_Rec: '',
                                    recursEveryWeek_Rec: '',
                                    weekDays: '',
                                    recursEveryMonth_Rec: '1',
                                    monthlyDay: '1',
                                    monthlyFreq: 'monthlySingle',
                                }));
                            }
                        }}
                        className="select-wrapper"
                        isDisabled={formData.scheduleType === 'Once' || enableForm===false}
                        >
                        </Select>
                    </div>
                </div>
            </div>

            {renderRecursEveryInput()}
            <br />

            {/* Section Divider */}
            <div className="styled-container">
                <div className="styled-divider-left"></div>
                <span className="styled-text">Daily Frequency</span>
                <div className="styled-divider-right"></div>
            </div>
            <div className="daily-frequency-container-2">
                <div className="occurs-once-at-2">
                    <div className='daily-frequency-section-1'>
                        <label className={`scheduler-label ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
                        <input
                            type="radio"
                            name="dailyFrequency"
                            value="occursOnceAt"
                            checked={formData.dailyFrequency === 'occursOnceAt'}
                            onChange={() => handleInputChange('dailyFrequency', 'occursOnceAt')}
                            disabled={formData.scheduleType === 'Once' || enableForm===false}
                        />
                        Occurs once at:
                         </label>
                    </div>
                    <div className="daily-frequency-section-2">
                    <div className={`daily-frequency-digital-clock-container-occurs-once-at ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
                            <span className='top-left-text'>Select time</span>
                            <div className="daily-frequency-digital-1">
                                {/* <ClockTimeInput onTimeChange={(time) => handleInputChange('endsAt_Rec', time)} disabled={formData.dailyFrequency === 'occursOnceAt' || formData.scheduleType === 'Once' || enableForm===false} /> */}
                                <DigitalClock 
                                hours={occursOnceAtRecHours} 
                                setHours={setOccursOnceAtRecHours} 
                                minutes={occursOnceAtRecMinutes} 
                                setMinutes={setOccursOnceAtRecMinutes} 
                                period={occursOnceAtRecPeriod} 
                                setPeriod={setOccursOnceAtRecPeriod} 
                                onTimeChange={(time) => handleInputChange('occursOnceAt_Rec', time)} 
                                darkMode={darkMode}
                                purpleMode={purpleMode} 
                                redMode={redMode} 
                                blueMode={blueMode} 
                                yellowMode={yellowMode}
                                disabled={formData.scheduleType === 'Once' || enableForm===false || formData.dailyFrequency === 'occursEvery'}
                            />
                            </div>
                    </div>
                    </div>
                    <div className="daily-frequency-section-3">

                    </div>
                </div>
                <div className="occurs-every-2">
                    <div className="daily-frequency-section-4">
                        <div className="daily-frequency-sub-section-1">
                            <label className={`scheduler-label ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
                                <input
                                    type="radio"
                                    name="dailyFrequency"
                                    value="occursEvery"
                                    checked={formData.dailyFrequency === 'occursEvery'}
                                    onChange={() => handleInputChange('dailyFrequency', 'occursEvery')}
                                    disabled={formData.scheduleType === 'Once' || enableForm===false}
                                />
                                Occurs every:
                                
                            </label>
                        </div>
                        <div className="daily-frequency-sub-section-2">
                            <input
                                type="number"
                                name="recursEveryDaily"
                                value={formData.recursEveryDaily}
                                onChange={(e) => {
                                    const inputValue = e.target.value === '' ? '' :  Math.max(1, e.target.value); 
                                    handleInputChange('recursEveryDaily', inputValue)}}
                                disabled={formData.scheduleType === 'Once' || enableForm===false || formData.dailyFrequency === 'occursOnceAt'}
                            />
                            <select
                                    name="recursEveryUnitDaily"
                                    value={formData.recursEveryUnitDaily}
                                    onChange={(e) => handleInputChange('recursEveryUnitDaily', e.target.value)}
                                    disabled={formData.scheduleType === 'Once' || enableForm===false || formData.dailyFrequency === 'occursOnceAt'}
                            >
                                    <option value="seconds">Seconds</option>
                                    <option value="minutes">Minutes</option>
                                    <option value="hours">Hours</option>
                            </select>
                        </div>
                        <div className="daily-frequency-sub-section-3">

                        </div>
                    </div>
                    <div className="daily-frequency-section-5">
                        <div className={`daily-frequency-digital-clock-container ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
                            <span className='top-left-text'>Starting at</span>
                            <div className="daily-frequency-digital-1">
                                {/* <ClockTimeInput onTimeChange={(time) => handleInputChange('startingAt_Rec', time)} disabled={formData.dailyFrequency === 'occursOnceAt' || formData.scheduleType === 'Once' || enableForm===false} /> */}
                                <DigitalClock 
                                    hours={startingAtRecHours} 
                                    setHours={setStartingAtRecHours} 
                                    minutes={startingAtRecMinutes} 
                                    setMinutes={setStartingAtRecMinutes} 
                                    period={startingAtRecPeriod} 
                                    setPeriod={setStartingAtRecPeriod} 
                                    onTimeChange={(time) => handleInputChange('startingAt_Rec', time)} 
                                    darkMode={darkMode}
                                    purpleMode={purpleMode} 
                                    redMode={redMode} 
                                    blueMode={blueMode} 
                                    yellowMode={yellowMode}
                                    disabled={formData.dailyFrequency === 'occursOnceAt' || formData.scheduleType === 'Once' || enableForm===false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="daily-frequency-section-6">
                        <div className={`daily-frequency-digital-clock-container ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
                            <span className='top-left-text'>Ending at</span>
                            <div className="daily-frequency-digital-1">
                                {/* <ClockTimeInput onTimeChange={(time) => handleInputChange('endsAt_Rec', time)} disabled={formData.dailyFrequency === 'occursOnceAt' || formData.scheduleType === 'Once' || enableForm===false} /> */}
                                <DigitalClock 
                                hours={endingAtRecHours} 
                                setHours={setEndingAtRecHours} 
                                minutes={endingAtRecMinutes} 
                                setMinutes={setEndingAtRecMinutes} 
                                period={endingAtRecPeriod} 
                                setPeriod={setEndingAtRecPeriod} 
                                onTimeChange={(time) => handleInputChange('endsAt_Rec', time)} 
                                darkMode={darkMode}
                                purpleMode={purpleMode} 
                                redMode={redMode} 
                                blueMode={blueMode} 
                                yellowMode={yellowMode}
                                disabled={formData.dailyFrequency === 'occursOnceAt' || formData.scheduleType === 'Once' || enableForm===false}
                            />
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>            

            {/* Section Divider */}
            <div className="styled-container">
                <div className="styled-divider-left"></div>
                    <span className="styled-text">Duration</span>
                <div className="styled-divider-right"></div>
            </div>

            <div className="duration-section">
                <label className={`scheduler-label ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
                    Start date:
                    <Calendar handleInputChange={handleInputChange} calendarType = {'startDate'} disabled={formData.scheduleType === 'Once' || enableForm===false} darkMode = {darkMode} purpleMode={purpleMode} blueMode={blueMode} yellowMode={yellowMode} formattedDate={formattedStartDate} />
                </label>
                <label className={`scheduler-label ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
                    End date:
                    <Calendar handleInputChange={handleInputChange} calendarType = {'endDate'} disabled={formData.scheduleType === 'Once' || formData.noEndDate || enableForm===false} darkMode = {darkMode} purpleMode={purpleMode} blueMode={blueMode} yellowMode={yellowMode}  formattedDate={formattedEndDate}/>
                </label>

                <label>
                    <input
                        type="checkbox"
                        name="noEndDate"
                        checked={formData.noEndDate}
                        onChange={(e) => handleInputChange('noEndDate', e.target.checked)}
                        disabled={formData.scheduleType === 'Once' || enableForm===false}
                    />
                    No End Date
                </label>
            </div>

            {/* Section Divider */}
            <div className="styled-container">
                <div className="styled-divider-left"></div>
                    <span className="styled-text">Summary</span>
                <div className="styled-divider-right"></div>
            </div>
            <div className="textarea-group">
                <label for="description" className={`scheduler-label ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>Description</label>
                <textarea
                    id="description"
                    name="description"
                    className="textarea"
                    rows="4"
                    value={descriptionPreview}
                    disabled
                ></textarea>
            </div>
            <br />
            <div className="scheduler-button-container">
                <button className="scheduler-button cancel" type="button" onClick={handleCancel}>Cancel</button>
                <button className="scheduler-button addJob"type="button" onClick={schedulersave}>Add Job</button>
            </div>
        </form>
        <Toaster />
        </div>
    );
}

export default Scheduler;

import React, { useState } from 'react';
import '../../style/Signup.css';
import { Link, useNavigate } from 'react-router-dom';

const Login = ({ setIsSignup }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await fetch((`${process.env.REACT_APP_API_URL}login`), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ UserName: username, Password: password }),
            });

            const data = await response.json();

            if (response.ok) {
                // This saves the JWT token to local storage
                localStorage.setItem('token', data.token);
                localStorage.setItem("user", JSON.stringify(data));
                navigate('/project');

                // Redirect or handle successful login
                console.log("Login successful", data);
            } else {
                setError(data.error || 'Login failed');
            }
        } catch (err) {
            console.error('Error during login:', err);
            setError('An error occurred during login');
        } finally {
            setLoading(false);
        }
    };

    const handleClick = () => {
        setIsSignup(true);
    };

    return (
        <div className='signup-card-right'>
            <h2>Login</h2>
            <form onSubmit={handleLogin} className="login-form">
                <div className="form-group-signup">
                    <label>Username</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        placeholder="Username"
                        required
                    />
                </div>
                <div className="form-group-signup">
                    <label>Password</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        required
                    />
                </div>
                {error && <div className="error-message">{error}</div>}
                <div className="form-group-signup form-group-full">
                    <button type="submit" disabled={loading}>
                        {loading ? 'Logging in...' : 'Login'}
                    </button>
                </div>
            </form>
            <div className="signup-footer">
                <p>Don't have an account? <Link onClick={handleClick}>Sign up here</Link></p>
            </div>
        </div>
    );
};

export default Login;

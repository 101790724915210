import React, {useState, useRef, useEffect} from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useNavigate } from 'react-router-dom';
import CollapseControl from "./CollapseControl";
import { GoHome } from "react-icons/go";
import { IoSettingsOutline} from "react-icons/io5";
import { IoMdNotificationsOutline } from "react-icons/io";
import { RiFolder2Line } from "react-icons/ri";
import {PiClockCounterClockwiseBold} from "react-icons/pi";
import { FiCalendar,FiLogOut } from 'react-icons/fi';
import { MdOutlineManageHistory } from "react-icons/md";
import AddProjectDialog from './AddProjectDialog';
import { FaRegCircleUser} from 'react-icons/fa6';
import '../style/SideBar.css'

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

const SlideSideBar = ({ darkMode, purpleMode, redMode, blueMode, yellowMode }) => {
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(true);
    const [isLogin, setLogin] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);

    const homeMenuItemRef = useRef(null);
    const projectsMenuItemRef = useRef(null);
    const connectorsMenuItemRef = useRef(null);
    const scheduleMenuItemRef = useRef(null);
    const runHistoryMenuItemRef = useRef(null);
    const logOutMenuItemRef = useRef(null);
    const settingsRef = useRef(null);
    const emailMenuItem = useRef(null);
    const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;

    const currentMode = darkMode ? 'dark' : purpleMode ? 'purple' : redMode ? 'red' : blueMode ? 'blue' : yellowMode ? 'yellow' : 'green';


    useEffect(() => {
        setUserEmail(loggedInUserEmail);
        const commonOptions = {
            animation: 'scale',
            placement: 'right',
        };

        const createTooltip = (element, content, theme) => {
            tippy(element, {
                ...commonOptions,
                theme: theme || '',
                content,
            });
        };
      
    
        if (collapsed) {
            createTooltip(homeMenuItemRef.current, 'Home', currentMode);
            createTooltip(projectsMenuItemRef.current, 'Projects', currentMode);
            createTooltip(connectorsMenuItemRef.current, 'Connectors', currentMode);
            createTooltip(scheduleMenuItemRef.current, 'Schedule', currentMode);
            createTooltip(runHistoryMenuItemRef.current, 'Run History', currentMode);
            createTooltip(logOutMenuItemRef.current, 'Log Out', currentMode);
            createTooltip(settingsRef.current, 'Settings', currentMode);

        } else {
            // Destroys tooltips for each element when the sidebar is not in collapsed mode
            const elementsToDestroy = [
                homeMenuItemRef.current,
                projectsMenuItemRef.current,
                connectorsMenuItemRef.current,
                scheduleMenuItemRef.current,
                runHistoryMenuItemRef.current,
                logOutMenuItemRef.current,
                settingsRef.current,
            ];
    
            elementsToDestroy.forEach((element) => {
                tippy(element).disable();
            });
        }
    }, [collapsed, currentMode]);
    

    const hoverBackgroundColor = collapsed ? '#B5D99B' : 'white';

    const handleToggleSidebar = () => {
        setCollapsed(!collapsed);
    };

    const logout = () => {
        localStorage.clear();
        setLogin(false);
        setUserEmail('');
        navigate('/login');
    };

    const handleLogoutClick = () => {
        logout(); 
      };

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
      };

    return (
        <div style={{ display: 'flex'}} className={collapsed ? 'collapsed' : ''}>
            <Sidebar collapsed={collapsed} onToggle={handleToggleSidebar} 
                backgroundColor={
                                    collapsed
                                      ? darkMode
                                        ? 'var(--dark-light)'
                                        : purpleMode
                                          ? 'var(--purple-default)'
                                          : redMode
                                            ? 'var(--red-default)'
                                            : blueMode
                                                ? 'var(--blue-default)'
                                                : yellowMode
                                                    ? 'var(--yellow-default)'
                                                    : 'var(--green-default)'
                                      : darkMode
                                        ? 'var(--dark-medium)'
                                        : purpleMode
                                          ? 'var(--purple-light)'
                                          : redMode
                                            ? 'var(--red-light)'
                                            : blueMode
                                                ? 'var(--blue-light)'
                                                : yellowMode
                                                    ? 'var(--yellow-light)'
                                                    : 'var(--green-light)'
                                  }    
                style={{transition: 'background-color 0.8s ease' }}  
                width={250}
                rootStyles={{ height: '100%' }} 
                className={`${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}
                >
                <CollapseControl onClick={handleToggleSidebar} collapsed={collapsed} setIsDialogOpen={setIsDialogOpen} darkMode={darkMode} purpleMode={purpleMode} redMode={redMode} blueMode={blueMode} yellowMode={yellowMode}/>                
                <div className="top-menu-buttons">
                    <Menu
                    >
                        <MenuItem ref={homeMenuItemRef} icon={<GoHome size={20} />} component={<Link to="/" />} className="pro-menu-item" > Home </MenuItem>
                        <MenuItem ref={projectsMenuItemRef} icon={<RiFolder2Line size={20}/>} component={<Link to="/Project" />} className="pro-menu-item"> Projects </MenuItem>
                        <MenuItem ref={connectorsMenuItemRef}icon={<PiClockCounterClockwiseBold size={20}/>} component={<Link to="/Project" />} className="pro-menu-item"> Connectors </MenuItem>
                        <MenuItem ref={scheduleMenuItemRef}icon={<FiCalendar size={20} />} component={<Link to="/Scheduler" />} className="pro-menu-item"> Schedule </MenuItem>
                        <MenuItem ref={runHistoryMenuItemRef}icon={<MdOutlineManageHistory size={20} />} component={<Link to="/RunHistoryMain" />} className="pro-menu-item"> Run History </MenuItem>
                        <MenuItem ref={logOutMenuItemRef}icon={<FiLogOut  size={20}/>} onClick={handleLogoutClick} className="pro-menu-item"> Log Out </MenuItem>
                    </Menu>
                </div>
                <div className='bottom-menu-buttons'>
                    <Menu>
                        <MenuItem icon={<IoMdNotificationsOutline size={20}/>} className="pro-menu-item"> Notifications </MenuItem>
                        <MenuItem ref={settingsRef} icon={<IoSettingsOutline size={20} />} component={<Link to="/Settings" />} className="pro-menu-item"> Settings </MenuItem>
                    </Menu>
                </div>
                <div className="bottom-icons-line-sidebar"></div>
                <p className='sidebar-menu-text'>Profile</p>
                <Menu>
                    <MenuItem className='pro-menu-profile-icon' ref={emailMenuItem} icon={<FaRegCircleUser size={20} />} onClick={toggleDropdown}>
                        <span className="pro-menu-item">Username</span><br />
                        <span className={`pro-user-email ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>{userEmail}</span>
                    </MenuItem>
                </Menu>
                        
                {/* </Menu> */}
                {isDialogOpen && (
                <AddProjectDialog
                    isOpen={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                    // onSave={handleSaveNewProject}
                    // showTargetDialog={showTargetDialog}
                    // setShowTargetDialog={setShowTargetDialog}
                    // projectNameValidationData = {projectNameValidationData}
                />
                )}
            </Sidebar>
        </div>
    );
};

export default SlideSideBar;
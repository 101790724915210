import React, { useState, useEffect } from 'react';
import '../style/UserProfile.css';

const UserProfile = () => {
    const [isEditingFirstName, setIsEditingFirstName] = useState(false);
    const [isEditingLastName, setIsEditingLastName] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [organisation, setOrganisation] = useState('');
    const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}get_user_profile?email=${loggedInUserEmail}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`, 
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setFirstName(data.First_Name || '');
                    setLastName(data.Last_Name || '');
                    setUsername(data.UserName || '');
                    setEmail(data.email || '');
                    setOrganisation(data.Organization_Name || '');
                } else {
                    console.error('Failed to fetch user profile:', await response.text());
                }
            } catch (err) {
                console.error('Error fetching user profile:', err);
            }
        };

        fetchUserProfile();
    }, [loggedInUserEmail]);

    const updateUserProfile = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}update_user_profile?email=${loggedInUserEmail}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({
                    first_name: firstName,
                    last_name: lastName,
                    email: email,
                    organization_name: organisation,
                }),
            });

            if (response.ok) {
                console.log('Profile updated successfully');
                setIsEditingLastName(!isEditingLastName);
            } else {
                console.error('Failed to update profile:', await response.text());
            }
        } catch (err) {
            console.error('Error updating profile:', err);
        }
    };

    const handleFirstNameChange = (e) => setFirstName(e.target.value);
    const handleLastNameChange = (e) => setLastName(e.target.value);
    const handleSave = async () => {
        await updateUserProfile();
        setIsEditingFirstName(false);
        setIsEditingLastName(false);
    };

    return (
        <div className="profile-container">
            <div className="profile-grid">
                <div className="profile-tile">
                    <label>Username</label>
                    <span>{username}</span>
                </div>
                <div className="profile-tile">
                    <label>Email</label>
                    <span>{email}</span>
                </div>
                <div className="profile-tile">
                    <label>Organisation</label>
                    <span>{organisation}</span>
                </div>
                <div className="profile-tile editable">
                    <label>First Name</label>
                    {isEditingFirstName ? (
                        <input
                            type="text"
                            value={firstName}
                            onChange={handleFirstNameChange}
                            onBlur={handleSave}
                            autoFocus
                        />
                    ) : (
                        <span>{firstName}</span>
                    )}
                    <button onClick={() => setIsEditingFirstName(!isEditingFirstName)}>
                        {isEditingFirstName ? 'Save' : 'Edit'}
                    </button>
                </div>
                <div className="profile-tile editable">
                    <label>Last Name</label>
                    {isEditingLastName ? (
                        <input
                            type="text"
                            value={lastName}
                            onChange={handleLastNameChange}
                            onBlur={handleSave}
                            autoFocus
                        />
                    ) : (
                        <span>{lastName}</span>
                    )}
                    <button onClick={() => setIsEditingLastName(!isEditingLastName)}>
                        {isEditingLastName ? 'Save' : 'Edit'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;

import React, { useState, useEffect, useCallback } from 'react';
import BreadcrumbItem from './Breadcrumbs';
import '../style/Project2.css';

const Project2 = () => {
    return (
        <div className='project-main-container'>
            <nav aria-label="breadcrumb-trails">
                <ul className="breadcrumb-unit">
                <BreadcrumbItem
                    to="/"
                    label="Home"
                    isActive={false}
                />
                <BreadcrumbItem
                    to="/Project2"
                    label="Active Projects"
                    isActive={true}
                />
                </ul>
            </nav>
            <div className='control-header'>

            </div>

        </div>)

}

export default Project2;
import React, { useState, useEffect } from 'react';
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from 'react-router-dom';
// import '../style/scheduler.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { FaQuestionCircle } from 'react-icons/fa';
import { CiFilter, CiSearch } from "react-icons/ci";
import HelpPage from './HelpPage';
import NewPage from './NewPage'; 
import Table from './ScheduleTable';
import EditScheduleDialog from './EditScheduleDialog'
import {BsFillPencilFill } from 'react-icons/bs';


// import '../style/Project.css';



var sprintf = require('sprintf-js').sprintf;

const Schedule = ({darkMode, purpleMode, redMode, blueMode, yellowMode}) => {
  const [data, setData] = useState([]);
  const [editingProject, setEditingProject] = useState(null);
  const [editingProjectValues, setEditingProjectValues] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showTargetDialog, setShowTargetDialog] = useState(false);
  const [deactivatedProjects, setDeactivatedProjects] = useState([]);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [showHelpDialog, setShowHelpDialog] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const filterOptions = ['true', 'false'];
  const [selectedFilter, setSelectedFilter] = useState('')
  const [showDraftProjects, setShowDraftProjects] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isHelpPageOpen, setIsHelpPageOpen] = useState(false);
  const [clickOnRow, setClickOnRow] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [jobNameValidationData, setJobNameValidationData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate(); 

  //Temporary values
  const detailsToSend = null;
  const [SuccessMessage,setSuccessMessage] = useState('');
  const [ButtonDisabled, setButtonDisabled] = useState(false);


  const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;

    const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const fetchProjects = () => {
    fetch(`${process.env.REACT_APP_API_URL}scheduler_details?useremail=${loggedInUserEmail}`)
      .then((response) => response.json())
      .then((jobs) => {
        setData(jobs.jobs);
        const jobNames = jobs.jobs.map((job) => job.JobName);
        setJobNameValidationData(jobNames);
        console.log('Data received in schedule: ', jobs.jobs);
      })
      .catch((error) => console.error('Error fetching data:', error));
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const toggleHelpPage = () => {
    setIsHelpPageOpen(!isHelpPageOpen);
  };

  useEffect(() => {
    // Open the help page automatically when the component mounts
    setIsHelpPageOpen(false);
  }, []);
  
  const showHelpPage = () => {
    setIsHelpPageOpen(true);
  };

  const closeHelpPage = () => {
    setIsHelpPageOpen(false);
  };


  const closeEditDialog = () => {
    setEditingProject(null);
    setEditingProjectValues({});
    setIsEditDialogOpen(false);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterChange = (option) => {
    setSelectedFilter(option);
    setIsFilterActive(true);
  };

  const openEditDialog = (project) => {
    setEditingProject(project);
    setEditingProjectValues(project);
    setIsEditDialogOpen(true);
  };



  const handleSortBy = (option) => {
    const sortedData = [...data];
    const isAsc = sortOrder === 'asc';
  
    switch (option) {
      case 'date':
        sortedData.sort((a, b) => (isAsc ? new Date(a.createddate) - new Date(b.createddate) : new Date(b.createddate) - new Date(a.createddate)));
        break;
        case 'status':
          sortedData.sort((a, b) => {
            const statusA = a.IsActive === true ? 0 : 1;
            const statusB = b.IsActive === true ? 0 : 1;
    
            if (isAsc) {
              return statusA - statusB;
            } else {
              return statusB - statusA;
            }
          });
          break;
          case 'update':
            sortedData.sort((a, b) => (isAsc ? new Date(a.UpdatedAt) - new Date(b.UpdatedAt) : new Date(b.UpdatedAt) - new Date(a.UpdatedAt)));
            break;
          default: break;
    }
  
    setData(sortedData);
    setSortOrder(isAsc ? 'desc' : 'asc');
  };

  const filteredData = data?.filter((job) => {
    const { JobName, ScheduleType, LoadType, ProjectName, Frequency, Description
    } = job;
    const searchLowerCase = searchQuery.toLowerCase();

    return (
      (!selectedFilter || selectedFilter === 'All' || job.IsActive.toString() === selectedFilter) &&
      (!showDraftProjects || job.status === 'Draft') &&
      ((JobName && JobName.toLowerCase().includes(searchLowerCase)) ||
        (ScheduleType && ScheduleType.toLowerCase().includes(searchLowerCase)) ||
        (LoadType && LoadType.toLowerCase().includes(searchLowerCase)) ||
        (ProjectName && ProjectName.toLowerCase().includes(searchLowerCase)) ||
        (Frequency && Frequency.toLowerCase().includes(searchLowerCase)) ||
        (Description && Description.toLowerCase().includes(searchLowerCase))
        )
    );
  });

    const openSchedulerFile = () => {
    // Open the Schedule.js component in a new window or tab
    window.open('/schedule', '_blank');
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setEditingProjectValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleSaveEdit = () => {
    if (
      !editingProjectValues.projectname.trim() ||
      !editingProjectValues.sourcesystem.trim() ||
      !editingProjectValues.targetsystem.trim()
    ) {
      return;
    }

    const currentDate = new Date();
    const lastUpdateDate = currentDate.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });

    const updatedData = data.map((project) =>
      project === editingProject ? { ...editingProjectValues, LastUpdateDate: lastUpdateDate } : project
    );
    
    setData(updatedData);
    setEditingProject(null);
    setEditingProjectValues({});
    saveDataToBackend(updatedData);
  };

    const handleCancelEdit = () => {
      setEditingProject(null);
      setEditingProjectValues({});
    };

    const handleDeactivate = (job) => {
      fetch(process.env.REACT_APP_API_URL+'deactivate_schedule', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: loggedInUserEmail, scheduleid: job.SchedulerId }),
      })
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.status === 'success') {
            console.log('Scheduled job deactivated successfully!');
            setData((prevData) => prevData.map((item) => item.JobName === job.JobName ? { ...item, IsActive: false } : item));
            setDeactivatedProjects((prevDeactivatedProjects) => [...prevDeactivatedProjects, job]);
           
            fetchProjects();
          } else {
            console.error('Error deactivating job:', responseData.message);
          }
        })
        .catch((error) => {
          console.error('Error deactivating job:', error);
        });
    };
  
    const handleReactivate = (job) => {
      fetch(process.env.REACT_APP_API_URL+'reactivate_schedule', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: loggedInUserEmail, scheduleid: job.SchedulerId }),
      })
        .then((response) => response.json())
        .then((responseData) => {
          if (responseData.status === 'success') {
            console.log('Scheduled job reactivated successfully!');
            setData((prevData) => prevData.map((item) => item.JobName === job.JobName ? { ...item, IsActive: true } : item));
            setDeactivatedProjects((prevDeactivatedProjects) => [...prevDeactivatedProjects, job]);
            fetchProjects();
          } else {
            console.error('Error reactivating job:', responseData.message);
          }
        })
        .catch((error) => {
          console.error('Error reactivating job:', error);
        });
    };

    const saveDataToBackend = (newProjectData) => {
      fetch(process.env.REACT_APP_API_URL + 'save_newproject', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({useremail:newProjectData.useremail, projectname:newProjectData.projectname,
          sourcesystem:newProjectData.sourcesystem, servernames:newProjectData.servernames, databasenames:newProjectData.databasenames,usernames:newProjectData.usernames,passwords:newProjectData.passwords,filepaths:newProjectData.filepaths, hostnames:newProjectData.hostnames, ports:newProjectData.ports, protocols:newProjectData.protocols, dbqs:newProjectData.dbqs, users:newProjectData.users, db_names:newProjectData.db_names
          ,taregtsystem:newProjectData.taregtsystem, servernamet:newProjectData.servernamet, databasenamet:newProjectData.databasenamet,usernamet:newProjectData.usernamet,passwordt:newProjectData.passwordt,filepatht:newProjectData.filepatht, hostnamet:newProjectData.hostnamet, portt:newProjectData.portt, protocolt:newProjectData.protocolt, dbqt:newProjectData.dbqt, usert:newProjectData.usert, db_namet:newProjectData.db_namet }),
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data.status === 'success') {
          console.log('Data saved successfully to the backend!');
        } else {
          console.error('Error saving data to the backend:', data.message);
        }
      })
        .catch((error) => {
          console.error('Error saving data to the backend:', error);
        });
    };

    const handleButtonClick = () => {
      setEditMode(false);
      navigate('/Schedule', { state: { jobNameValidationData } });
    };

    const handleEditButtonClick = (clickedItem) => {
      // console.log('In schedule. Clicked item: ',clickedItem);
      setEditMode(true);
      navigate('/Schedule', { state: { jobNameValidationData, editMode: true, clickedItem } });
    };

    return (    
        <div className={`flex ${darkMode ? 'dark-mode' : ''}`}> 
           <div className="flex-grow">
           {/* <div>
                <img src={require('../img/thumbnail_4Vue-V5.jpg')} alt="4SEER Logo"  className="logo-image"/>
            </div> */}
           <div>
        <nav aria-label="breadcrumb">
          <ul className="flex breadcrumb">
            <li className="breadcrumb-item">
              <Link
                to={{pathname: '/',}}
                className={`inactive-link ${darkMode ? 'dark-mode' : ''}`}>
                  Home
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <a href="/Scheduler" className={`active-link ${darkMode ? 'dark-mode' : ''}`}>
                Scheduler
              </a>
            </li>
          </ul>
        </nav>
        <div className="mt-2 d-flex justify-content-between mb-4">
          <div>
          <div className='Mainname'>
          <h4 className={`page-heading ${darkMode ? 'dark-mode' : ''}`}>SCHEDULER</h4>
        </div>
              <span className="sub-title">Schedule and Manage your projects</span>
            </div>
            <button className="add-project-button" onClick={handleButtonClick}>
               + Schedule Job
              </button>
        </div>
        <ul
        className="nav nav-tabs d-flex align-items-center"
        id="myTab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <a
            className="nav-link active"
            id="overview-tab"
            data-bs-toggle="tab"
            href="#overview"
            role="tab"
            aria-controls="overview"
            aria-selected="true"
          >
            Overview
          </a>
        </li>
        <li className="flex-grow-1 d-flex justify-content-end align-items-center">
        <span className={`sort-container ${darkMode ? 'dark-mode' : ''}`}>Sort By:</span>
          <div className="form-check form-check-inline custom-radio">
          <input
            className="form-check-input"
            type="radio"
            name="sortOption"
            id="sortByCreationDate"
            value="creationDate"
            checked={handleSortBy === 'date'}
            onChange={() => handleSortBy('date')}
            />            
            <label className={`form-check-label ${darkMode ? 'dark-mode' : ''}`} htmlFor="sortByCreationDate">
              Creation Date
            </label>
          </div>
          <div className="form-check form-check-inline custom-radio">
          <input         
              className="form-check-input"
              type="radio"
              name="sortOption"
              id="sortByCreationDate"
              value="Status"
              checked={handleSortBy === 'update'}
              onChange={() => handleSortBy('update')}
            />
            <label className="form-check-label" htmlFor="sortByLastUpdate">
              Last Update
            </label>
          </div>
          <div className="form-check form-check-inline custom-radio">
           <input
            className="form-check-input"
            type="radio"
            name="sortOption"
            id="sortByActiveStatus"
            value="status"
            checked={handleSortBy === 'status'} 
            onChange={() => handleSortBy('status')}
            />
       <label className="form-check-label" htmlFor="sortByActiveStatus">
        Active Status
        </label>
          </div>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="overview"
          role="tabpanel"
          aria-labelledby="overview-tab"
        >
          <div className={`d-flex justify-content-between mt-4 search-container ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
            <div className={`input-container ${darkMode ? 'dark-mode' : ''}`}>
              <input
                type="text"
                placeholder="Search by job name."
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <div className="icon">
                <CiSearch />
              </div>
            </div>
            <div>
              <button className="filter-button" onClick={() => setIsFilterActive(!isFilterActive)}>
                <CiFilter className="unfilled-icon" /> Filter
              </button>
              {isFilterActive && (
          <div className="filter-options">
            <button
              className={`filter-option ${selectedFilter === 'All' ? 'active' : ''}`}
              onClick={() => handleFilterChange('All')}
            >
              All
            </button>
            {filterOptions.map((option) => (
              <button
                key={option}
                className={`filter-option ${selectedFilter === option ? 'active' : ''}`}
                onClick={() => handleFilterChange(option)}
              >
                {option === 'true'? 'Active': 'In-active'}
              </button>
            ))}
          </div>
        )}
       </div>
       </div>
       <div className='custom-table'>
            <div className="custom-table-header">
                          <div style={{ flex: 1 }}>JOB NAME</div>
                          <div style={{ flex: 1, textAlign: 'center' }}>SCHEDULE TYPE</div>
                          <div style={{ flex: 1, textAlign: 'center' }}>PROJECT NAME</div>
                          <div style={{ flex: 1, textAlign: 'center' }}>LOAD TYPE</div>
                          <div style={{ flex: 1, textAlign: 'left' }}>LAST UPDATE</div>
                          <div style={{ flex: 1, textAlign: 'center' }}>FREQUENCY</div>
                          <div style={{ flex: 1, textAlign: 'left' }}>DESCRIPTION</div>
                          <div style={{ flex: 1, textAlign: 'center' }}>ACTIONS</div>
            </div>

       </div>
       <div className={`scrollable-box ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
          <div className="mt-2">
              <div className="custom-table">

              <div className="custom-table-body">
  {filteredData?.length === 0 ? (
    <div className="no-data-message">No data available.</div>
  ) : (
    (filteredData ?? []).map((item, index) => (
      <div
        key={index}
        className={`custom-table-row`}
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={handleMouseLeave}
        onClick={() => setClickOnRow(index)}
        style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}
      >
        <div className="custom-table-cell custom-left">
          <span className="active">
            <button
              className={`status-button ${
                item.IsActive === true ? "active-status" : "In-Active"
              }`}
            >
              {item.IsActive === true ? "🟢" : "🔴"}
            </button>
          </span>
          {item.JobName}
        </div>
        <div className="custom-table-cell" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{item.ScheduleType}</div>
        <div className="custom-table-cell" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{item.ProjectName}</div>
        <div className="custom-table-cell" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{item.LoadType}</div>                            
        <div className="custom-table-cell" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{item.UpdatedAt}</div>
        <div className="custom-table-cell" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{item.Frequency}</div>
        <div className="custom-table-cell">{item.Description}</div>
        <div className="custom-table-cell custom-right" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <label className="toggle-switch small-toggle" style={{ position: 'relative', display: 'inline-block' }}>
            <input
              type="checkbox"
              checked={item && item.IsActive === true}
              onChange={() => {
                if (item && item.IsActive === true) {
                  console.log('Within if condition');
                  handleDeactivate(item);
                } else {
                  console.log('Within else condition');
                  handleReactivate(item);
                }
              }}
            />
            <span className="slider"></span>
          </label>
          <div className="custom-overlay">
            <span className="edit-link">
              <span className="edit-text">Edit</span>
              <BsFillPencilFill
                className="pencil-icon"
                onClick={() => handleEditButtonClick(item.SchedulerId)}
              />
            </span>
          </div>
        </div>
      </div>
    ))
  )}
</div>

                    </div>
                  </div>
                </div>
           </div>
       <div
          className="tab-pane fade"
          id="drafts"
          role="tabpanel"
          aria-labelledby="drafts-tab"
        >
          Content for the Drafts tab goes here.
        </div>
      </div>
    </div>
    <div className="help-icon" >
      <div className={` ${isHelpPageOpen ? 'help-container' : 'help-container-closed'}`}>      
      <p className={` ${isHelpPageOpen ? 'menu-text-help-bot' : 'question-mark-style '}`} onMouseEnter={showHelpPage} onClick={closeHelpPage} >
        {isHelpPageOpen ? 'May I help you?' : '?'}
       </p>
           {isHelpPageOpen && <HelpPage onClose={toggleHelpPage} />}
            </div>
            <div>     
       </div>
    </div>

      {isEditDialogOpen && (
              <EditScheduleDialog
                isOpen={isEditDialogOpen}
                onClose={closeEditDialog}
                project={editingProject}
                editingProjectValues={editingProjectValues}
                handleInputChange={handleInputChange}
                handleSaveEdit={handleSaveEdit}
                handleCancelEdit={handleCancelEdit}
              />
      )}

</div>
</div>


  );
}

export default Schedule;
import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import '../style/Connector.css';
import AddProjectDialog from './AddProjectDialog';
import HelpPage from './HelpPage';
import NewPage from './NewPage';
import NavBar from './NavBar';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { FaQuestionCircle } from 'react-icons/fa';
import { CiSearch } from "react-icons/ci";

const Project = ({ darkMode, purpleMode, redMode, blueMode, yellowMode }) => {
  const [data, setData] = useState([]);
  const [editingProject, setEditingProject] = useState(null);
  const [editingProjectValues, setEditingProjectValues] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isHelpPageOpen, setIsHelpPageOpen] = useState(false);
  const [showHelpDialog, setShowHelpDialog] = useState(false);
  const [clickOnRow, setClickOnRow] = useState(null);
  const [showTargetDialog, setShowTargetDialog] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigate = useNavigate();
  const loggedInUserEmail = JSON.parse(localStorage.getItem('user'))?.email;
  const isactive = 1;
  const { projectname } = useParams();

  const fetchProjectConfig = () => {
    fetch(process.env.REACT_APP_API_URL+`projectconfig?email=${loggedInUserEmail}&isactive=${isactive}&projectid=${projectname}`)
      .then((response) => response.json())
      .then((projectconfig) => {
        console.log('Projects received from backend:', projectconfig); // Add this line to check the received data
        setData(projectconfig);
      })
      .catch((error) => console.error('Error fetching data:', error));
  };
  
  useEffect(() => {
    fetchProjectConfig();
  }, [loggedInUserEmail, isactive, projectname]);
   
  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setEditingProjectValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleHelpPage = () => {
    setIsHelpPageOpen(!isHelpPageOpen);
  };

  useEffect(() => {
    // Open the help page automatically when the component mounts
    setIsHelpPageOpen(true);
  }, []);
  
  const showHelpPage = () => {
    setIsHelpPageOpen(true);
  };

  const closeHelpPage = () => {
    setIsHelpPageOpen(false);
  };

  const filteredData = data.filter((projectconfig) => {
    const { projectname, sourcesystem, sourceconfiguration, targetsystem,targetconfiguration, createddate, LastUpdateDate } = projectconfig;
    const searchLowerCase = searchQuery.toLowerCase();
    return (
      (projectname && projectname.toLowerCase().includes(searchLowerCase)) ||
      (sourcesystem && sourcesystem.toLowerCase().includes(searchLowerCase)) ||
      (sourceconfiguration && sourceconfiguration.toLowerCase().includes(searchLowerCase)) ||
      (targetsystem && targetsystem.toLowerCase().includes(searchLowerCase)) ||
      (targetconfiguration && targetconfiguration.toLowerCase().includes(searchLowerCase)) ||
      (createddate && createddate.toLowerCase().includes(searchLowerCase)) ||
      (LastUpdateDate && LastUpdateDate.toLowerCase().includes(searchLowerCase))
    );
  });

  const handleSaveNewProject = (newProjectData) => {
    if (!newProjectData.projectname.trim() || !newProjectData.sourcesystem.trim() || !newProjectData.taregtsystem.trim()) {
      return;
    }

    const currentDate = new Date();
    const createdDate = currentDate.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });
    const newProject = {
      projectname: newProjectData.name,
      sourcesystem: newProjectData.source,
      sourceconfiguration: newProjectData.sourceconfiguration,
      targetsystem: newProjectData.target,
      targetconfiguration: newProjectData.targetconfiguration,
      createddate: createdDate,
      LastUpdateDate: createdDate,
    };
    const updatedData = [...data, newProject];
    setData(updatedData);
    setIsDialogOpen(false);
  };

  return (
    <div className='flex'>
    {/* <NavBar />
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      /> */}
  <div className="flex-grow">
  {/* <img src={require('../img/thumbnail_4Vue-V5.jpg')} alt="4SEER Logo"  className="logo-image"/> */}
  <div>
    <nav aria-label="breadcrumb">
      <ul className="d-flex breadcrumb">
        <li className="breadcrumb-item">
        <Link
                to={{pathname: '/',}}
                className="inactive-link">
                  Home
        </Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
            <a href="/Project" className="inactive-link">
              Active Projects
            </a>
          </li>
        <li className="breadcrumb-item active" aria-current="page">
          <a href="#" className="active-link">
            Connector Page
          </a>
        </li>
      </ul>
    </nav>
    <div className="mt-2 d-flex justify-content-between mb-4">
      <div className='Mainname'>
        <h4 className="page-heading">Project Level Adaptors</h4>
        <span className="sub-title">
          Create, Edit and Manage your projects
        </span>
      </div>
    </div>
  
    <div className="tab-content" id="myTabContent">
      <div
        className="tab-pane fade show active"
        id="overview"
        role="tabpanel"
        aria-labelledby="overview-tab"
      >
        <div className={`d-flex justify-content-between mt-4 search-container ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
          <div className="input-container">
            <input
              type="text"
              placeholder="Search by project name, amount..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <div className="icon">
              <CiSearch />
            </div>
          </div>

        </div>
        <div className="custom-table">
            <div className="custom-table-header">
              <div style={{ flex: 1 }}>PROJECT</div>
              <div style={{ flex: 1 }}>SOURCE</div>
              <div style={{ flex: 1}}>SOURCE CONFIGURATION</div>
              <div style={{ flex: 1, textAlign: 'center' }}>TARGET</div>
              <div style={{ flex: 1 }}>TARGET CONFIGURATION</div>
              <div style={{ flex: 1, textAlign: 'center' }}>LAST UPDATE</div>
            </div>
      </div>
        <div className={`scrollable-box ${darkMode ? 'dark-mode' : ''} ${purpleMode ? 'purple-mode' : ''} ${redMode ? 'red-mode' : ''} ${blueMode ? 'blue-mode' : ''} ${yellowMode ? 'yellow-mode' : ''}`}>
        <div className="mt-4">
            <div className="custom-table">
              <div className="custom-table-body">
                {filteredData.map((projectconfig, index) => (
                  <div
                    key={index}
                    className={`custom-table-row`}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => setClickOnRow(index)}
                  >
                  <div className="custom-table-cell custom-left" >
                      {projectconfig.projectname}
                  </div>
                  <div className="custom-table-cell">
                      {/* <div className="custom-table-container-row" style={{ overflowY: "scroll", maxHeight: "200px", borderTopLeftRadius: "10px", borderBottomRightRadius: "10px" }}> */}
                        {projectconfig.sourcesystem}
                      {/* </div> */}
                  </div>


                  <div className="custom-table-cell" style={{ overflowX: "auto" }}>
                    {/* <div className="custom-table-container-row" style={{ overflowX: "auto" }}> */}
                      {/* <div style={{ width: "500px",borderBottomRightRadius: "10px" }}> */}
                        {projectconfig.sourceconfiguration}
                      {/* </div> */}
                    {/* </div> */}
                  </div>

                  <div className="custom-table-cell" style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* <div className="custom-table-container-row" style={{ overflowY: "scroll", maxHeight: "200px", borderTopLeftRadius: "10px", borderBottomRightRadius: "10px" }}> */}
                      {projectconfig.targetsystem}
                      {/* </div> */}
                  </div>
                  <div className="custom-table-cell" style={{ overflowX: "auto" }}>
                    {/* <div className="custom-table-container-row" style={{ overflowX: "auto" }}> */}
                      {/* <div style={{ width: "500px" }}> */}
                        {projectconfig.targetconfiguration}
                      {/* </div>
                    </div> */}
                  </div>
                  <div className="custom-table-cell custom-right" style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* <div className="custom-table-container-row"> */}
                        {projectconfig.LastUpdateDate}
                    {/* </div> */}
                  </div>

                  </div>
                ))}
              </div>

            </div>
          </div>
          </div>
      </div>
      <div
        className="tab-pane fade"
        id="drafts"
        role="tabpanel"
        aria-labelledby="drafts-tab"
      >
        Content for the Drafts tab goes here.
      </div>
    </div>
  </div>
  <div className="help-icon" >
    <div className=
      {` ${isHelpPageOpen ? 
                      `help-container ${darkMode ? 'dark-mode' : ''}`: `help-container-closed ${darkMode ? 'dark-mode' : ''}`}`}>      
      <p className={` ${isHelpPageOpen ? `menu-text-help-bot ${darkMode ? 'dark-mode' : ''}` : `question-mark-style ${darkMode ? 'dark-mode' : ''} `}`} onMouseEnter={showHelpPage} onClick={closeHelpPage} >
        {isHelpPageOpen ? 'May I help you?' : '?'}
       </p>
        {isHelpPageOpen && <HelpPage onClose={toggleHelpPage} darkMode={darkMode} purpleMode={purpleMode} redMode={redMode} blueMode={blueMode} yellowMode={yellowMode} />}                  
      </div>
    </div> 

  {isDialogOpen && (
        <AddProjectDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onSave={handleSaveNewProject}
          showTargetDialog={showTargetDialog}
          setShowTargetDialog={setShowTargetDialog}
        />
      )}

</div>
</div>
);
}
export default Project;